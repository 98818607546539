import { FormattedMessage } from 'react-intl';
import { Container, Segment, Header, Card, Grid, List } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { keys, isEmpty, map } from 'lodash';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import { Carousel } from '../../'; 
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';


// CAROUSEL ITEM
const ItemView = props => {
  const {item} = props

  return (
    <div className="testiItem__wrapper">
      <Card as="article" className="testiItem" key={item['@id']}  >
        <img className='ui image testiItem__img'
          src={
            getScaleUrl(getFieldURL(item.image), 'preview') ||
            DefaultImageSVG
          }
          alt=''
        />
        <img className='ui image testiItem__img'
          src={
            getScaleUrl(getFieldURL(item.bottomImage), 'preview') ||
            DefaultImageSVG
          }
          alt=''
        />
          <Card.Header as="h3" className="testiItem__title">{item.title}</Card.Header>
          <Card.Description as="p" className="testiItem__text">
            {item.description}
          </Card.Description>         

      </Card> 
    </div>
  )
};


const View = (props) => {
  const { data, mode } = props;
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const SLIDE_OPTIONS = {
    loop: false,
    autoplay: false,
    dots: true,
    buttons: false,
    customClass: 'testimonials-slider',
    stopOnMouseEnter: true,
    slidesToScroll: 'auto'
  }

  return (
    <section className={'testimonials ' + fullWidthClass + backgroundClass + (data?.items?.length < 2 ? ' testimonials--1item' : '') + (data?.items?.length < 3 ? ' testimonials--2item' : '')}>
      <Container className="">
        {!isEmpty(data?.items) && (<Carousel slides={data.items.slice(0, 12)} ItemView={ItemView} options={SLIDE_OPTIONS} /> )}
        <Grid className="">
          <Grid.Row columns={12}>
            <Grid.Column className="centered" mobile={12} tablet={10} computer={8} largeScreen={6}>
              {data?.title && (<Header as="h2" size='tiny' className='testimonials__title text-center justify-content-center mb-4 fs-4'>{data.title}</Header>)}
              {data?.description && (<p className='testimonials__intro text-center'>{data.description}</p>)}
              {data?.contactLink && (
                <p className='text-center pt-5 mb-0'>
                  <UniversalLink href={data.contactLink} className='button ui primary mt-4 px-4'>
                    <FormattedMessage id="Contact us" defaultMessage="Contact us" />
                  </UniversalLink>
                </p>
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>
    </section>
  );
};

export default View;
